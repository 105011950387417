import React, { Component } from "react"
import {
  Hero,
  HeroBody,
  Container,
  Title,
  Button,
  Columns,
  Column,
  Icon,
} from "bloomer"

export default class hero extends Component {
  render() {
    return (
      <Hero isColor="primary" isSize="medium" textalign="left">
        <HeroBody>
          <Container>
            <Columns>
              <Column style={{ margin:'0 auto' }} isSize="3/4">
                {/* Headline */}
                <Title>{this.props.description}</Title>
                {/* Call to action button */}
              </Column>
            </Columns>
          </Container>
        </HeroBody>
      </Hero>
    )
  }
}
