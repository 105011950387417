import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import {
  Section,
  Container,
  Title,
  Column,
  Content,
  Button,
  Icon,
} from "bloomer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const IndexPage = ({ data }) => {
  const page = data.site.siteMetadata
  return (
    <Layout>
      <SEO />
      <Hero siteTitle={page.title} description={page.description} />
      <Section>
        <Container>
          {/* Intro section  */}
          <Column style={{ margin: '0 auto' }} isSize="3/4">
            <Title>Technology, Startups & Products</Title>
            <Content>
              <p>
                Building <a href={"https://leapcode.io/"} target="_blank" className="hover hover-1">
                    leapcode.io
                    </a> to help first time contributors get started with open source. 
                    </p>
                    <p>Previously, designed & managed
                startup programs at 91springboard to help tech startups
                in India on product, UX, marketing & sales with Google for Startups. Prior to that I worked as a Frontend
                Engineer at Infosys and Lead at Google's Women Techmakers Trivandrum. I am a
                Computer Science & Engineering graduate - a Prime Minister Scholar &
                Grace Hopper Women in Computing Scholar.
                <br></br>
                <br></br>
                I enjoy building products for developer & startup communities
              </p>
              {/* List of features */}
              <ul id="list-unstyled" className='lii'>
                <li className="example">
                  <a href={"https://leapcode.io/"} target="_blank" className="hover hover-1">
                    leapcode
                    </a>
                </li>
                <br></br>
                <li className="example">
                  <Link to="/startup/" className="hover hover-1">
                    Startups vs COVID-19
                    </Link>
                </li>
                <br></br>
                <li className="example">
                  <a href={"https://anitab.org/membership/"} target="_blank" className="hover hover-1">
                    Anita B.org Mentoring Platform
                    </a>
                </li>
                <li className="example">
                  <Link to="/leapcode-women/" className="hover hover-1">
                    Women in Tech Community
                    </Link>
                </li>

              </ul>
              {/* Navigate to page 2 */}
              <p>
                <Link to="/learnings/">
                  <Button
                    isColor="primary"
                    className="is-rounded"
                    id="btn-spaced"
                  >
                    <span>Access my learnings</span>
                    <Icon className="fa fa-arrow-right fa-sm" />
                  </Button>
                </Link>
              </p>
            </Content>
          </Column>

          {/* Features section begins */}
        </Container>
      </Section>
    </Layout >
  )
}

IndexPage.propTypes = {
  site: PropTypes.shape({
    siteMetadata: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  }),
}
export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
